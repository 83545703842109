import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

library.add(faCheck);

// Define functional component with TypeScript
const Newsletter: React.FC = () => {
  // Use state hook with TypeScript
  const [showNewsletterEmail, setShowNewsletterEmail] = useState(true);
  const [lblNewsletterRegistration, setLblNewsletterRegistration] = useState("Sign-up for free newsletter");
  const [inputEmail, setInputEmail] = useState('');

  // Toggle newletter registration
  function toggleNewsletterRegistration() {
    if (inputEmail) {
      window.location.href = 'mailto:teacher.peace.info@gmail.com?subject=Subscribe%20to%20TeacherPeace%20Newsletter&body=Your%20decision%20to%20join%20our%20community%20means%20a%20lot%20to%20us!';
      setShowNewsletterEmail(false);
      setLblNewsletterRegistration("Your best is yet to come! Glory to God!");
    }
  }

  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setInputEmail(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      toggleNewsletterRegistration();
    } 
  };

  // Render component
  return (
   <div>
    <div className='app-moto-newsletter-hint'>{lblNewsletterRegistration}</div>
    { showNewsletterEmail ? (
        <div className='app-moto-newsletter'>
        <form>
            <input type='text' id="newsletter" value={inputEmail} onChange={handleChange} onKeyPress={handleKeyPress} placeholder='Enter email address'></input>
        </form>
        <a className='btn-app-moto-newsletter' onClick={toggleNewsletterRegistration}>SUBMIT</a>
        </div>
    ): (
        <div className='app-moto-newsletter-added'>
        <FontAwesomeIcon icon="check" size = '2x'/>
        </div>
    )}
   </div>
  );
};

export default Newsletter;
