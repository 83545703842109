import React from 'react';
import { getYear } from "date-fns";
import './App.css';
import Newsletter from './components/Newsletter';

function App() {
  return (
    <div className="App">
      <header className="app-header">
        <div className='app-logo'>Teacher Peace</div>
        <div></div>
      </header>
      <section className='app-body'>
        <div className='app-moto-accent'>
          <div>Peace</div>
          <div>Teacher</div>
        </div>
        <div className='app-moto'>
          <div className='app-moto-sub1'>
            Empowering Educators, <br></br>Cultivating Peace,<br></br>Fostering Harmony,<br></br>Inspiring Minds.
          </div>
          <div className='app-moto-sub2'>
          An online oasis for gaining peace before, during, and after school.
          <br></br>Stopping the pain, and moving into peace.
          </div>
          <Newsletter></Newsletter>
        </div>
        <div className='app-moto-logo'></div>
      </section>
      <footer className='app-footer'>
        <div className='app-copyright'>Copyright © <b>{getYear(Date.now())} TeacherPeace</b> Inc. All rights reserved.</div>
        </footer>
    </div>
  );
}

export default App;
